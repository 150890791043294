import React from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import {CookiesPoup} from "./homepage"

export default function TermsAndCondtions() {
  return (
    <>
      <TitleAndMetaTags
        title="Privacy Policy Acceptance | Workativ"
        description="Workativ takes people privacy very seriously. We ask for certain information and ensure it is used to help educate you on Workativ offerings."
        keywords={["workativ terms and conditions"]}
        ogTitle="Privacy Policy Acceptance | Workativ"
        ogDescription="Workativ takes people privacy very seriously. We ask for certain information and ensure it is used to help educate you on Workativ offerings."
      />
      <Container>
      <Layout logoFor="WORKATIV" product="WORKATIV">
          <section className="terms_condtions">
            <div className="space-explorations">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 space-explorations-content">
                    <h1>PRIVACY POLICY </h1>
                  </div>
                </div>
              </div>
            </div>
            <TermsContent />
            {/* <RequestForm isFooterForm={true} /> */}
          </section>
          {/* <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

function TermsContent() {
  return (
    <>
      <section className="statistics-boggle privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <p>
                We are committed to protecting your privacy. In order to provide
                You with uninterrupted use of Our Services, We may collect and
                process, and, in some circumstances, and by law, disclose
                information about you with your permission. To ensure better
                protection of Your privacy, We provide this notice explaining
                how we collect and process your information including where
                required by law our disclosure policies, and your choices in
                regards to the use, access, correction and deletion of your
                Personal Data. This privacy notice applies to a) entities or
                individuals, who have subscribed to our Service(s) and have
                agreed to the Terms (hereinafter referred to as the “Customer”)
                and b) individuals (“Individuals”) from whom We collect data as
                a controller.
              </p>
              <p>
                This Privacy Policy shall be in compliance with the General Data
                Protection Regulation (GDPR) in effect from May 25, 2018, and
                any and all provisions that may read to the contrary shall be
                deemed to be void and unenforceable as of that date. If you do
                not agree with the terms and conditions of our Privacy Policy,
                including in relation to the manner of collection or use of your
                information, please do not use or access the Services. If you
                have any questions or concerns regarding this Privacy Policy,
                you should contact our us at{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@workativ.com"
                >
                  <b>support@workativ.com</b>
                </a>
              </p>
              <p>
                ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING
                ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED
                HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS
                PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR
                THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO
                INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.{" "}
              </p>
              <h3>DEFINITIONS: </h3>
              <div className="alpha_letters">
                <ol type="a">
                  <li>
                    “We”, “Our”, and “Us” shall mean and refer to the creators
                    of this privacy policy.{" "}
                  </li>
                  <li>
                    “You”, “Your”, “Yourself” and “User” shall mean and refer to
                    natural and legal individuals who use the Website.{" "}
                  </li>
                  <li>
                    “Personal Information” shall mean and refer to any
                    personally identifiable information that We may collect from
                    You. For removal of any doubts, please refer to Clause 2.{" "}
                  </li>
                  <li>
                    “Third Parties” refer to any website, company or individual
                    apart from the User and the creator of this Website.{" "}
                  </li>
                  <li>
                    Any capitalized terms used in this Notice but not defined
                    herein shall have the same meaning as defined in the{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      className="url_manipulation"
                      href="/terms"
                    >
                      Terms of Service
                    </a>
                    .{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <h3>
                COLLECTION, PROCESSING, RIGHTS, AND RETENTION OF YOUR DATA{" "}
              </h3>
              <p>
                We are committed to protecting Your privacy. We further
                recognize Your need for appropriate protection and management of
                any Personal Information You share with us.
              </p>
              <h3 className="text-uppercase">
                1. We collect and process personal data when:{" "}
              </h3>
              <div className="alpha_letters">
                <ol type="a">
                  <li>
                    <b>Visitor: </b> When you visit our Websites, we may collect
                    information, which may include Personal Data, from
                    Individuals as set forth below (collectively referred to as
                    “Collected Data”). For the purposes of the General Data
                    Protection Regulation (GDPR), We shall be the controller for
                    the Collected Data – this means that We decide what
                    Collected Data is processed and why.{" "}
                  </li>
                  <li>
                    <b>Signup: </b> When you subscribe and sign-up to any of our
                    Service(s), we may collect your (i) contact information such
                    as name, e-mail address, mailing address, IP address,
                    geographic location, or phone number of the Account admin;
                    (ii) billing information, such as credit card number and
                    billing address; (iii) name and e-mail address when Account
                    admin provide feedback from within the Service(s); and (iv)
                    unique identifiers, such as username, account number or
                    password.{" "}
                    <span
                      style={{ marginTop: 15, width: "100%", float: "left" }}
                    />
                    <br /> Subject to this Notice and the Terms, we will use
                    such data, including without limitation, to (i) provide you
                    the Service(s); (ii) send you communication from the
                    Service(s); (iii) assess needs of your business to determine
                    or suggest suitable Service(s); (iv) send you requested
                    information about the Service(s); (v) respond to customer
                    service requests, questions and concerns; (vi) administer
                    your Account; (vii) send you promotional and marketing
                    communications (where you have requested us to do so); and
                    (viii) facilitate your transactions with other users when
                    you use our Service(s).{" "}
                  </li>
                  <li>
                    <b>Marketing &amp; Promotion: </b> When you signed up with
                    Us at either a third-party conference or event or registered
                    for a marketing program such as newsletter, webinars or
                    seminars, we may collect your contact information such as
                    name, e-mail address, designation, phone number, company
                    name, company location, size and mailing address et. Subject
                    to this Notice, we will use such data, including without
                    limitation, to (i) assess needs of your business to
                    determine or suggest suitable Service(s); (ii) send you
                    requested information about the Service(s); (iii) send you
                    promotional and marketing communications (where you have
                    requested us to do so); and (iv) respond to your questions
                    and concerns.{" "}
                  </li>
                  <li>
                    <b>Social Media: </b> Our Websites includes social media
                    features, such as the Facebook “Like” button, the “Share
                    This” button or interactive mini-programs. Where you
                    interact with these features, they may collect your IP
                    address, which page you are visiting on our Websites, and
                    may set a cookie to enable the feature to function properly.
                    Social media features and widgets are either hosted by a
                    third party or hosted directly on our Websites. Your
                    interactions with these features are governed by the privacy
                    notice of the company providing them.{" "}
                  </li>
                  <li>
                    <b>Cookies: </b> We and our third-party advertising partners
                    use cookies and similar technologies in analyzing trends,
                    administering the website, tracking users’ movements around
                    the site, and gathering demographic information about our
                    user base as a whole. We may receive reports based on the
                    use of these technologies by these companies on an
                    individual and aggregated basis. Most web browsers support
                    cookies and users can control the use of cookies at the
                    individual browser level. Please note that if you choose to
                    disable cookies, it may limit your use of certain features
                    or functions on our Websites and services.{" "}
                    <br style={{ marginTop: 10 }} /> As is true of most
                    websites, we gather certain information automatically and
                    store it in log files. This information may include internet
                    protocol (IP) addresses, browser type, internet service
                    provider (ISP), referring/exit pages, the files viewed on
                    our Website (e.g., HTML pages, graphics, etc.), operating
                    system, date/time stamp, and/or clickstream data. We link
                    this automatically collected data to other data we collect
                    about you. We do this mainly to improve services We offer
                    you, to improve marketing, analytics, and/or Website
                    performance and functionality.
                  </li>
                  <li>
                    <b>Analytics: </b> Apart from the aforementioned information
                    collected by us, we automatically receive and record certain
                    Personal Data of yours when You visit our Websites. This
                    includes device model, IP address, the type of browser being
                    used, usage pattern through cookies and browser settings,
                    query logs and product usage logs. We also collect clicks,
                    scrolls, conversion and drop-off on our Websites and
                    Service(s) to render user journey at real-time. Subject to
                    this Notice, we will use such data, including without
                    limitation, to (i) assess needs of your business to
                    determine or suggest suitable Service(s); (ii) send you
                    requested information about the Service(s); (iii) respond to
                    customer service requests, questions and concerns; and (iv)
                    for analytical purposes. You acknowledge and authorize Us
                    and Our service providers to perform analytics on such
                    Collected Data, to (i) improve, enhance, support and operate
                    the Websites; and (ii) compile statistical reports and
                    record insights into usage patterns.
                  </li>
                </ol>
              </div>
              <h3 className="text-uppercase">2. Processing service data: </h3>
              <p>
                We only process Service Data as per our Customer's instructions.
                For purposes of the GDPR and the Swiss Federal Act on Data
                Protection, we are the processor and not the controller of the
                Service Data. Service Data, as defined in the Terms, means all
                electronic data, text, messages or other materials, including
                Personal Data of Users and End-Users, submitted to the
                Service(s) by our Customers through Customer’s Account in
                connection with Customer’s use of the Service(s), including data
                collected under “Usage Information” below. Our EEA or
                Switzerland based Customers are the “controllers” of that data
                and are responsible for compliance with the applicable data
                protection law.
              </p>
              <p>
                If you are our Customer from EEA or Switzerland, then in your
                role as a controller, you are authorizing, on behalf of you and
                your authorized Users and End-Users, and representing that you
                have the authority to provide such authorization to the
                processing and transfer of Personal Data in and to the United
                States and other countries which may have different privacy laws
                from your or their country of residence. We will take all steps
                reasonably necessary to ensure that the Service Data is treated
                securely and in accordance with this Notice.{" "}
              </p>
              <p>
                We do not own, control or direct the use of Service Data, and in
                fact we are largely unaware of what information is being stored
                on our platform and only access such information as reasonably
                necessary to provide the Service(s) (including to respond to
                support requests), as otherwise authorized by Customers or as
                required by law. Unless we explicitly agree otherwise in
                writing, you will not process sensitive personal data (such as
                health data) on our platform.{" "}
              </p>
              <p className="alpha_p">
                As the controller, it shall be your responsibility to inform the
                End-Users about the processing, and, where required, obtain
                necessary consent or authorization for any Personal Data that is
                collected as part of the Service Data through your use of the
                Service(s). As the processors of Personal Data on behalf of our
                Customers, we follow Customer’s instructions with respect to the
                Service Data to the extent consistent with the functionality of
                our Service(s). In doing so, we implement technical, physical
                and administrative measures against unauthorized processing of
                such information and against loss, destruction of, or damage to,
                Personal Data.{" "}
              </p>
              <div className="alpha_letters">
                <ol type="a">
                  <li>
                    <b>Service Usage Information: </b> <br /> Where a User or
                    End-User uses the Service(s), we automatically receive and
                    record certain information of such user. This information
                    includes Username, email address, device model, IP address,
                    the type of browser being used, usage pattern through
                    cookies and browser settings, query logs and product usage
                    logs (collectively referred to as “Usage Information”).{" "}
                  </li>
                  <li>
                    <b>Service Improvement and Enhancement: </b> <br /> You
                    expressly authorize us and our service providers we use to
                    process the Service Data in our systems to (i) provide,
                    improve, enhance, support and operate the Service(s) and its
                    availability; (ii) develop new products and services; and
                    (iii) compile statistical reports and record insights into
                    usage patterns.{" "}
                  </li>
                </ol>
              </div>
              <h3 className="text-uppercase">3. Processing personal data:</h3>
              <p className="alpha_p">
                We process Personal Data in the United States and the European
                Economic Area (“EEA”) and in other countries through third
                parties that we may use.
              </p>
              <p className="alpha_p">
                We may also share your Personal Data as follows:{" "}
              </p>
              <div className="alpha_letters" style={{ marginBottom: 40 }}>
                <ul>
                  <li>
                    With the third party assisting us in providing you with the
                    Service(s). Our Sub-Processors are given access to
                    Customer’s Account and Service Data only as reasonably
                    necessary to provide the Service(s) and will be subject to
                    confidentiality obligations in their service agreements.{" "}
                  </li>
                  <li>
                    With third-party service providers providing services, such
                    as research and analytics, marketing and data enrichment or
                    for them to reach out to you on our behalf.{" "}
                  </li>
                  <li>
                    With third-party payment processors who process your credit
                    card and other payment information for us but are otherwise
                    not permitted to store, retain or use such information.{" "}
                  </li>
                  <li>
                    With third-party partners for the purpose of assisting us in
                    onboarding you and providing any further support needed to
                    use our Service(s).{" "}
                  </li>
                </ul>
              </div>
              <h3 className="text-uppercase">
                4. Your Data Protection Rights{" "}
              </h3>
              <h3> EEA AND SWISS SPECIFIC RIGHTS</h3>
              <h6>a. Collected Data: </h6>
              <p className="alpha_p">
                If you are an individual resident in EEA or Switzerland, you
                have the following data protection rights regarding Collected
                Data:{" "}
              </p>
              <div className="alpha_letters">
                <ul>
                  <li>
                    If you wish to access, correct, update or request deletion
                    of your Personal Data, you can do so at any time by
                    contacting us.{" "}
                  </li>
                  <li>
                    You can object to the processing of your Personal Data, ask
                    us to restrict processing of your Personal Data or request
                    portability of your Personal Data. Again, you can exercise
                    these rights by contacting us.{" "}
                  </li>
                  <li>
                    You have the right to opt-out of marketing communications we
                    send you at any time. You can exercise this right by
                    clicking on the “unsubscribe” or “opt-out” link in the
                    marketing e-mails we send you. To opt-out of other forms of
                    marketing (such as postal marketing or telemarketing),
                    please contact us.{" "}
                  </li>
                  <li>
                    Similarly, if we have collected and processed your Personal
                    Data with your consent, then you can withdraw your consent
                    at any time. Withdrawing your consent will not affect the
                    lawfulness of any processing we conducted prior to your
                    withdrawal, nor will it affect the processing of your
                    Personal Data conducted in reliance on lawful processing
                    grounds other than consent.{" "}
                  </li>
                  <li>
                    You have the right to complain to a data protection
                    authority about our collection and use of your Personal
                    Data. For more information, please contact your local data
                    protection authority.{" "}
                  </li>
                </ul>
              </div>
              <p>
                If you seek access to or wish to correct, update, modify or
                delete Personal Data (hereinafter referred to as a “Request”)
                which forms a part of Collected Data, please contact us at{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@workativ.com"
                >
                  <b>support@workativ.com</b>
                </a>
                . We respond to all requests we receive from individuals wishing
                to exercise their data protection rights within a reasonable
                timeframe in accordance with applicable data protection laws.{" "}
              </p>
              <h6>b. Service Data:</h6>
              <p className="alpha_p">
                We acknowledge that you have the right to access your Personal
                Data. We have no direct relationship with the individuals whose
                Personal Data we process. If you seek access to or wish to
                correct, update, modify or delete Personal Data (hereinafter
                referred to as a “Request”) which is part of the Service Data
                and processed by us on behalf of our Customer or if you are an
                End-User of one of our Customers and would no longer like to be
                contacted by one of our Customers that uses our Service(s), you
                should direct your query to our Customer i.e., the controller.
                if requested to remove data, We will respond within a reasonable
                timeframe.{" "}
              </p>
              <p>
                If you are a Customer of our Service(s) and wish to raise a
                Request on behalf of your Users and End-Users in connection with
                Service Data, you may raise a ticket on the support portal of
                the relevant Service. Please note that if a Customer has
                subscribed to more than one Service, a Request on a particular
                Service support portal is specific to that Service only and
                separate Requests need to be raised across other relevant
                Service support portals.{" "}
              </p>
              <h3>CALIFORNIA-RESIDENT SPECIFIC RIGHTS </h3>
              <p className="alpha_p">
                To the extent, that you are a 'consumer' as defined under the
                California Consumer Privacy Act of 2018 ("CCPA") and Workativ is
                a 'business' as defined under CCPA, the following applies to
                you:{" "}
              </p>
              <p>
                Subject to the provisions of the CCPA, you have the right to
                request in the manner provided herein, for the following:{" "}
              </p>
              <h6>a. Right to request for information about the: </h6>
              <div className="alpha_letters" style={{ marginBottom: 30 }}>
                <ul>
                  <li>
                     Categories of Personal Data we have collected about you.{" "}
                  </li>
                  <li>
                    Specific pieces of Personal Data we have collected about
                    you.{" "}
                  </li>
                  <li>
                    Categories of sources from which the Personal Data is
                    collected.{" "}
                  </li>
                  <li>
                    Business or commercial purpose for collecting Personal Data.{" "}
                  </li>
                  <li>
                    Categories of third parties with whom the business shares
                    Personal Data.{" "}
                  </li>
                </ul>
              </div>
              <h6>
                b. Right to request for deletion of any Personal Data collected
                about you by us:
              </h6>
              <p>
                If you seek to exercise the foregoing rights to access or delete
                Personal Data which constitutes 'personal information' as
                defined in CCPA, please contact us at{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@workativ.com"
                >
                  <b>support@workativ.com</b>
                </a>
                . We respond to all requests we receive from you wishing to
                exercise your data protection rights within a reasonable
                timeframe in accordance with applicable data protection laws. By
                writing to us, you agree to receive communication from us
                seeking information from you in order to verify you to be the
                consumer from whom we have collected the Personal Data from and
                such other information as reasonably required to enable us to
                honour your request.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <h3>5. RETENTION OF PERSONAL DATA </h3>
              <div className="alpha_letters">
                <ol type="a">
                  <li>
                    If you wish to request that we no longer use your Collected
                    Data, please contact us at 
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:support@workativ.com"
                    >
                      <b> support@workativ.com</b>
                    </a>
                    .
                  </li>
                  <li>
                    Personal Data contained in the Service Data is retained and
                    deleted in accordance with the Terms.{" "}
                  </li>
                  <li>
                    Notwithstanding the foregoing, we will retain Collected Data
                    and Service Data as necessary to comply with our legal
                    obligations, for litigation/defense purposes, maintain
                    accurate financial and other records, resolve disputes, and
                    enforce our agreements.
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>6. SECURITY</h3>
              <p>
                We treat data as an asset that must be protected against loss
                and unauthorized access. We employ many different security
                techniques to protect such data from unauthorized access by
                members inside and outside the Company. We follow generally
                accepted industry standards to protect the Personal Information
                submitted to Us and information that we have accessed.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>7. IMPORTANT COMMUNICATIONS</h3>
              <p>
                If you are our Customer, we will send you announcements related
                to the Service(s) on occasions when it is necessary to do so.
                For instance, if our Service(s) is temporarily suspended for
                maintenance, we might send you an e-mail. Generally, you may not
                opt-out of communications which are not promotional in nature.
                If you do not wish to receive them, you may deactivate your
                Account.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>8. LINKS TO THIRD PARTY SITES </h3>
              <p>
                Our Websites contain links to other websites that are not owned
                or controlled by us. Please be aware that we are not responsible
                for the privacy practices of such other websites or third
                parties. We encourage you to be aware when you leave our
                Websites and to read the privacy policies of each and every
                website that collects Personal Data.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>9. AMENDMENT </h3>
              <p>
                Our Privacy Policy may change from time to time. If we make any
                material changes, we will notify you by means of a notice on
                this Website prior to the change becoming effective and if you
                are our Customer, via e-mail (specified in your Account).
                Provided we will not be notifying you if we amend the Notice to
                make addition, deletions or modifications to the list of cookies
                from time to time to keep the list of cookies current and
                accurate. You should frequently visit this Notice to check for
                amendments. Your continued use of our Websites or the Service(s)
                following the posting of any amendment, modification, or change
                to this Notice shall constitute your acceptance of the
                amendments to this Notice. You can choose to discontinue use of
                the Websites or Service(s) if you do not accept the terms of
                this Notice, or any modified version of this Notice.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>10. LEGAL DISCLOSURE </h3>
              <p>
                We, including our Group Companies reserve the right to disclose
                your personal data contained in Collected Data and Service Data
                as required by applicable law, in response to lawful requests by
                public authorities, including meeting national security or law
                enforcement requirements and when we believe that disclosure is
                necessary to protect our rights and/or to comply with a judicial
                proceeding, court order, or other legal process served on us.
                Collected Data and Service Data will also be shared between our
                Group Companies for the activities permitted under the Terms and
                this Notice.{" "}
              </p>
              <p>
                In the event we go through a business transition, such as a
                merger or acquisition by another company, or sale of all or a
                portion of its assets, Customer’s Account, Collected Data and
                Service Data will likely be among the assets transferred. A
                prominent notice will be displayed on our Websites to intimate
                you of any such change in ownership or control and Customers
                will be notified via an e-mail from 
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@workativ.com"
                >
                  <b>support@workativ.com</b>
                </a>
                .
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>11. CONTACT WORKATIV </h3>
              <p>
                If you have any questions or concerns regarding this privacy
                policy, you should contact us by sending an e-mail to{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@workativ.com"
                >
                  <b>support@workativ.com</b>
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}
